<template>
	<div class="cbox">

		<div style="" class="cxbox">

			<div class="title">考试分数查询</div>

			<div>
				<el-form ref="form" :model="EditItem">

					<el-row>
						<el-col :span="24">
							<el-form-item>

								<el-input v-model="EditItem.zkzh" prefix-icon="el-icon-user"
									placeholder="请输入准考证号"></el-input>

							</el-form-item>

						</el-col>

						<el-col :span="24">
							<el-form-item>

								<el-input v-model="EditItem.sfz" prefix-icon="el-icon-postcard"
									placeholder="请输入完整身份证号"></el-input>

							</el-form-item>

						</el-col>


						<el-col :span="18">
							<el-form-item>

								<el-input v-model="EditItem.yzm" prefix-icon="el-icon-watch"
									placeholder="请输入验证码"></el-input>

							</el-form-item>

						</el-col>

						<el-col :span="6">
							<el-form-item>

								<div style="margin-top: -10px;" v-html="yzm.data"></div>

							</el-form-item>

						</el-col>


						<el-col :span="24">
							<el-form-item style="text-align: center; padding-top: 20px;">

								<el-button type="primary" style="width: 200px;float: left; background-color: #6f95f8;"
									@click="search">查询</el-button>
								<el-button style="width: 200px; float: right; background-color: #e2eef7;"
									@click="resetForm">重置</el-button>

							</el-form-item>

						</el-col>



					</el-row>



				</el-form>
			</div>

		</div>



		<el-dialog title="考试分数" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px">
			<div slot="title">
				<div style="font-size: 18px; font-weight: bolder; border-bottom: 1px solid #E2E4ED; padding-bottom: 20px; text-align: center;">
					
					考试分数
				</div>
				
			</div>
			<div style="font-size: 16px;">
				<span>姓名：</span><span style="font-weight: bolder; margin-right: 100px;">{{ resultData.xm }}</span>
				<span>准考证号：</span><span style="font-weight: bolder; margin-right: 100px;">{{ resultData.zkzh }}</span>
				<span>身份证号：</span><span style="font-weight: bolder; margin-right: 30px;">{{ resultData.sfz }}</span>
			</div>
			<div style="padding-top: 30px;font-size: 16px;">
				<span style="font-weight: bolder;">

					<img :src="'./img/xsm.png'"
						style="width: 22px; height: 22px; vertical-align: middle; transform: translateY(-3px);">

					课程+成绩</span>

				<!-- <span style="float: right;">发布时间：{{ resultData.createdAt }}</span> -->
			</div>
			<table class="dtable" style="font-size: 16px; margin-top: 20px;">
				<tr>
					<td v-for="(course,k,i) in Courses " :key="i"
						v-if="resultData.detailJson&&resultData.detailJson[k]>=0"
						style="background-color: #EDF1FD; padding: 20px 0;">{{course}}</td>
				</tr>
				<tr>

					<td v-for="(course,k,i) in Courses " :key="i"
						v-if="resultData.detailJson&&resultData.detailJson[k]>=0" style=" padding: 20px 0;">
						{{ resultData.detailJson[k] }}</td>

				</tr>

			</table>
		</el-dialog>




	</div>
</template>

<script>
	let course = require("../pages/kecheng.js")
	export default {
		components: {

		},
		data() {
			return {
				dialogVisible: false,
				EditItem: {
					zkzh: "",
					sfz: "",
					yzm: ""
				},
				Courses: course.course,

				resultData: {},
				yzm: {
					data: "",
					text: ""
				}

			};
		},

		mounted() {

			this.getYzm()
		},
		methods: {
			resetForm() {
				this.EditItem = {
					zkzh: "",
					sfz: "",
					yzm: ""
				}
			},
			getYzm() {
				this.$http.post("/api/kaofen_yzm").then(res => {
					this.yzm = res.data
				})
			},
			search() {
				if (!this.EditItem.zkzh) {
					this.$message.error("请输入考生号")
					return
				}

				if (!this.EditItem.sfz) {
					this.$message.error("请输入完整身份证号")
					return
				} else {
					var myreg =
						/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
					if (!myreg.test(this.EditItem.sfz)) {

						this.$message.error("请输入正确的身份证号")
						return
					}
				}

				if (!this.EditItem.yzm) {
					this.$message.error("请输入验证码")
					return
				}

				if (this.EditItem.yzm.toLowerCase() != this.yzm.text.toLowerCase()) {
					this.$message.error("验证码不正确！")
					return
				}

				this.$http.post("/api/kaofen_search", this.EditItem).then(res => {
					if (res.data && res.data.id) {
						if (res.data.detailJson) {
							res.data.detailJson = JSON.parse(res.data.detailJson)
						} else {
							res.data.detailJson = {}
						}
						this.resultData = res.data
						this.dialogVisible = true
					} else {
						this.$alert('未查询到考试分数结果！', '查询提示', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});
					}
				})

			}

		}
	}
</script>


<style scoped>
	.title {
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		padding-bottom: 40px;
	}

	.cxbox {
		width: 500px;
		height: 400px;
		margin: 0 auto;
		padding: 30px 50px;
	}
</style>