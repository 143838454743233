let course ={
	DXYW:"大学英语",
	JYXXLXJBYL:"教育学心理学基本原理",
	YEJYXXLXJBYL:"幼儿教育学心理学基本原理",
	DXYY:"大学语文",
	YYXJS:"音乐学加试",
	XQJYJS:"学前教育加试",
	ZYCS:"专业测试",
	YEJYXXLX:"幼儿教育学心理学",
	ZYKMY:"专业科目一",
	ZYKME:"专业科目二",
	ZYKMS:"专业科目三"
}
module.exports={
	course
}